<template>
  <div>
    <section class="payment-fail-header">
      <icon icon-name="fail-icon" class="icon"></icon>
      <h3 class="title">{{$t('paymentFailed')}}</h3>
<!--      <p class="price">15 000 ₸</p>-->
    </section>
    <swipe-modal
        v-model="isModal"
        contents-height="50vh"
        border-top-radius="16px"
        :persistent="true"
        backgroundColor="#F7F7F7"
        tip-color="#c8c8c8"
        contents-color="#F7F7F7"
    >
      <sos-feedback></sos-feedback>
    </swipe-modal>
    <router-link :to="{name: 'Welcome'}" class="primary-btn bottom-page-btn">{{$t('goBack')}}</router-link>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import swipeModal from "@takuma-ru/vue-swipe-modal";
import SosFeedback from "../../SosFeedback";

export default {
  name: "PaymentFail",
  components:{
    swipeModal,
    SosFeedback
  },
  data(){
    return {
      isModal:false,
    }
  },
  async mounted() {
    let stage = {
      name: 'Ошибка при оплате',
      id: '8',
      error: 'Произошла ошибка при оплате'
    };
    this.isModal = true
    await this.sendErrorsToRedmine(stage);
  },
  methods: {
    ...mapActions(['ogpoForm/sendErrorsToRedmine'])
  }
}
</script>

<style lang="scss" scoped>
@import "../../../sass/variables";
.payment-fail-header {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 26px 16px 50px;
  margin-bottom: 16px;
  .icon {
    margin-bottom: 24px;
  }
  .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .price {
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 8px;
  }
  .desc {
    color: #566681;
  }
}
</style>
